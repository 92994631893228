import React from "react";

const SelectDropdown = ({
  htmlFor,
  id,
  label,
  value,
  optionPlaceholder,
  options,
  handleSelectChange,
}) => {
  return (
    <div className="w-full flex flex-col gap-1">
      <label
        htmlFor={htmlFor}
        className="font-poppins font-medium text-base text-gray-1"
      >
        {label}
      </label>
      <select
        id={id}
        value={value}
        onChange={handleSelectChange}
        className={`focus:bg-white p-2 w-full rounded-lg font-poppins text-base placeholder:text-gray-4 outline-none bg-transparent border`}
      >
        <option value="" disabled>
          {optionPlaceholder}
        </option>
        {options?.map((item, index) => {
          return (
            <option key={index} value={item?.id}>
              {item?.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectDropdown;
