// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/swiper-custom.css */
.swiper-pagination-bullet {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity));
    padding: 0.25rem;
    opacity: 0.75;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms
}

.swiper-pagination-bullet-active {
    border-width: 1px;
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
    opacity: 1;
    outline-style: solid;
    outline-width: 1px;
    outline-offset: 2px;
    outline-color: #6F9CFF
}
`, "",{"version":3,"sources":["webpack://./src/pages/LandingPages/Home/swiper-custom.css"],"names":[],"mappings":"AAAA,0BAA0B;AAExB;IAAA,kBAAiE;IAAjE,yDAAiE;IAAjE,gBAAiE;IAAjE,aAAiE;IAAjE,4BAAiE;IAAjE,wDAAiE;IAAjE;AAAiE;;AAIjE;IAAA,iBAA4F;IAA5F,kBAA4F;IAA5F,wDAA4F;IAA5F,UAA4F;IAA5F,oBAA4F;IAA5F,kBAA4F;IAA5F,mBAA4F;IAA5F;AAA4F","sourcesContent":["/* src/swiper-custom.css */\n.swiper-pagination-bullet {\n  @apply p-1 bg-gray-400 opacity-75 transition-opacity duration-300;\n}\n\n.swiper-pagination-bullet-active {\n  @apply bg-blue-500 opacity-100 border outline outline-1 outline-custom-blue outline-offset-2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
