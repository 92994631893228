import { DashboardLayout } from 'components/Layout'
import React from 'react'

export const AdsPage = () => {
    return (
        <DashboardLayout activeSidebar={"Ads"}>
            AdsPage
        </DashboardLayout>
    )
}
