import React, { useEffect, useState } from "react";
import IMAGES from "assets/IMAGES";
import { getAllPartcipants } from "api/UserManagement";
import { formatDateToDDMMYYYY } from "utils/formatDateToDDMMYYYY";

export const AllParticipents = () => {
  const [allparticipetns, setAllparticipetns] = useState();
  const [loading, setLoading] = useState(false);

  console.log("allparticipetns", allparticipetns);

  const fetchAllPartcipantsData = async () => {
    try {
      setLoading(true);
      const data = await getAllPartcipants();
      setAllparticipetns(data.results);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPartcipantsData();
  }, []);

  const participants = [
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
    {
      id: 8,
    },
    {
      id: 9,
    },
  ];
  return (
    <div className="flex flex-col w-full gap-8 lg:col-span-4">
      <div className="flex flex-row w-full font-poppins font-semibold items-center justify-between gap-5">
        <h3 className=" lg:text-xl text-base text-gray-1 min-w-max">
          All participants
        </h3>
        <div className="border-gray-5 border-2 w-full"></div>
      </div>

      <div className="flex flex-col gap-5 px-1">
        {allparticipetns?.map((value, index) => {
          return (
            <>
              <div className="flex md:flex-row bg-red-50 flex-col md:items-center items-start justify-between py-4 px-4 shadow-sm border bg-opacity-10 rounded-2xl gap-4 w-full">
                <div className="flex flex-row space-x-3 items-center">
                  <p className=" text-[#33333359] font-poppins font-medium">
                    {value?.user_rank}
                  </p>
                  <div className="flex flex-row gap-4">
                    <img
                      src={IMAGES?.winnerPic}
                      alt=""
                      className="object-contain h-12 w-12"
                    />
                    <div className="font-poppins text-base">
                      <p className="text-gray-1 font-semibold font-poppins ">
                        {value?.user?.first_name} {value?.user?.last_name}
                      </p>
                      <div className="font-normal text-gray-4 flex flex-row space-x-1">
                        <p>Score:</p>
                        <span className="text-custom-blue">
                          {value?.user_score}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" flex flex-row space-x-3 justify-between md:w-auto w-full items-center">
                  <div className="flex flex-col items-start justify-center">
                    <div className="flex items-center space-x-1 font-poppins lg:text-sm text-sm">
                      <p className="font-normal text-gray-4">Date:</p>
                      <p className="font-semibold text-gray-1">
                        {formatDateToDDMMYYYY(value?.created_at)}
                      </p>
                    </div>
                    <div className="flex items-center space-x-1 font-poppins lg:text-sm text-sm">
                      <p className="font-normal text-gray-4">Province:</p>
                      <p className="font-semibold text-gray-1">
                        {value?.user?.profile?.city}(
                        {value?.user?.profile?.province})
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};
