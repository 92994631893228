import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DashboardLayout } from "components/Layout";
import { useGetUserReview } from "api/AuctionManagement";
import Svgs from "assets/svgs";

function AllReviewsPage() {
    const { name } = useParams()
    console.log(name)
    const { data: userReviews } = useGetUserReview(
        name === "investments" ? "investment_project" : name === 'auction' ? 'auction' : ''
    )


    const navigate = useNavigate();

    return (
        <DashboardLayout activeSidebar={name === "investments" ? "Investments" : "Auction"}>
            <div className="flex flex-row gap-x-4 items-center min-w-max">
                <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.9654 14C6.9049 13.9958 6.8895 13.9964 6.82959 13.986C6.69489 13.9628 6.56533 13.9117 6.45103 13.8367C6.39322 13.7988 6.34277 13.7547 6.29237 13.7077L0.292113 7.70743C0.250711 7.66308 0.23916 7.65298 0.202409 7.60453C0.161157 7.55012 0.125505 7.49147 0.0961541 7.42977C-0.0320514 7.16021 -0.0320514 6.84034 0.0961541 6.57078C0.125505 6.50908 0.161157 6.45043 0.202409 6.39602C0.23916 6.34762 0.250711 6.33747 0.292113 6.29317L6.29237 0.292913C6.33672 0.251511 6.34687 0.23991 6.39527 0.203209C6.52238 0.106755 6.67239 0.0416518 6.82959 0.0145006C6.94185 -0.00480021 7.0572 -0.00480021 7.16946 0.0145006C7.25921 0.0300013 7.34677 0.0578025 7.42902 0.0969042C7.74143 0.245461 7.95894 0.553624 7.99434 0.897738C8.00829 1.03374 7.99404 1.17225 7.95269 1.30256C7.91824 1.41111 7.86519 1.51356 7.79638 1.60427C7.75963 1.65272 7.74808 1.66282 7.70668 1.70717L2.41355 7.0003L7.70668 12.2934L7.75328 12.3433C7.79153 12.3903 7.80243 12.4013 7.83574 12.4521C7.89819 12.5473 7.94419 12.6531 7.97114 12.7637C7.99269 12.8523 8.00209 12.9437 7.99899 13.0347C7.98719 13.3804 7.79118 13.7027 7.48967 13.8722C7.39042 13.928 7.28166 13.9667 7.16946 13.986C7.10955 13.9964 7.09415 13.9958 7.03365 14C7.0109 14 6.98815 14 6.9654 14Z"
                        fill="black"
                    />
                </svg>
                <div className="flex flex-row capitalize cursor-pointer">
                    <p onClick={() => navigate(-1)} className=" text-black">
                        {name}/
                    </p>
                    <p className="text-custom-blue">All reviews</p>
                </div>
            </div>
            <div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-4">
                    {userReviews?.results?.map((value, i) => {
                        return (
                            <>
                                <div
                                    key={i}
                                    className="border p-3 md:p-4 rounded-xl  my-0">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-3 ">
                                            {value?.display_pic
                                                ?
                                                <img
                                                    src={value?.display_pic}
                                                    alt="Profile Pic"
                                                    className='aspect-square 
                                                        h-[32px] w-[32px]
                                            md:h-[49px] md:w-[49px] bg-white border rounded-xl object-cover' />
                                                :
                                                <div
                                                    className='h-[32px] w-[32px]
                                            md:h-[49px] md:w-[49px] rounded-full bg-custom-blue text-white  text-xs md:text-base text-center flex items-center justify-center '>
                                                    {value?.user?.first_name[0]}
                                                    {value?.user?.last_name[0]}
                                                </div>
                                            }

                                            <div>
                                                <h1 className="md:text-xl text-base font-semibold font-poppins capitalize text-[#414042] whitespace-nowrap  max-w-[200px] overflow-hidden truncate">
                                                    {value?.user?.first_name} {value?.user?.last_name}
                                                </h1>
                                                <p className=" text-sm  md:text-base font-normal font-poppins text-[#7A7A7A]">
                                                    {value?.investment_project != null ? "Investor" : "Bidder"}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="flex flex-row items-center justify-center gap-[7px]">
                                            {Array(5).fill()?.map((_, index) => (
                                                <Svgs.ReviewStarIcon color={value?.rating >= index + 1 ? "#FFB543" : "#C5C5C5"} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="pt-4">
                                        <p
                                            className=" text-sm font-normal font-poppins text-gray-4">
                                            {value?.text}
                                        </p>
                                    </div>
                                </div>
                            </>
                        );
                    })}

                </div>
            </div>
        </DashboardLayout>
    );
}

export default AllReviewsPage;
