import React, { useState } from "react";
import IMAGES from "assets/IMAGES";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup"; // Import Yup for validation schema
import Spinner from "components/Spinner";
import { toast } from "react-toastify";
import { forgotPassword, otpVerification } from "api/UserManagement";

// Custom validation schema for OTP
const otpValidationSchema = Yup.object({
  otp: Yup.string()
    .length(4, "OTP must be exactly 4 digits")
    .required("OTP is required"),
});

// Custom validation schema for the forgot password form
const validationSchema = Yup.object().shape({
  emailOrMobile: Yup.string()
    .test(
      "emailOrMobile",
      "Enter a valid email or mobile number",
      function (value) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Basic email format
        const mobileRegex = /^[0-9]{10,15}$/; // Example mobile number validation
        return emailRegex.test(value) || mobileRegex.test(value);
      }
    )
    .required("This field is required"),
});

export const ForgotPassword = () => {
  const [otpForm, setOtpForm] = useState(false);
  const [emailOrMobile, setEmailOrMobile] = useState(""); // Store email/mobile
  const [isPending, setIsPending] = useState();
  const navigate = useNavigate();

  localStorage.setItem("emailOrMobile", emailOrMobile);

  // forgot password api function
  const handleSubmit = async (values) => {
    const data = {
      email_or_phone_no: values.emailOrMobile,
    };
    setIsPending(true);
    try {
      const response = await forgotPassword(data);
      toast.success("OTP has been sent successfully.");
      setEmailOrMobile(values.emailOrMobile); // Save email/mobile to state
      setOtpForm(true); // Switch to OTP form after sending the OTP
    } catch (error) {
      console.error("API Error:", error.response || error.message);
    } finally {
      setIsPending(false); // Stop loading spinner
    }
  };

  // otp form submission handler
  const handleOtpSubmit = async (values) => {
    const data = {
      email_or_phone_no: emailOrMobile,
      otp: values.otp,
    };
    setIsPending(true);
    try {
      const response = await otpVerification(data);
      toast.success("OTP verification successful.");
      navigate("/reset-password");
    } catch (error) {
      toast.error("OTP verification failed. Please try again.");
      console.error("API Error:", error.response || error.message);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <>
      <div className="bg-custom-blue max-sm:py-16 relative z-50 min-h-screen h-fit flex justify-center items-center px-1 overflow-hidden">
        <img
          src={IMAGES.backgroundLogo}
          alt=""
          className="absolute right-0 top-0 h-[36rem] -rotate-8"
        />
        <img
          src={IMAGES.logoWithName}
          alt=""
          className="absolute left-0 bottom-10 h-60 -rotate-8 -z-50"
        />
        {!otpForm ? (
          // Forgot Password Form
          <div className="bg-white md:p-5 py-4 rounded-xl flex flex-col w-[95vw] xs:w-[85vw] space-y-4  md:w-[60vw] lg:w-3/5 xl:w-2/5 min-h-fit xl:min-h-[540px] items-center justify-center z-50 ">
            <div className="lg:w-3/4 w-full items-center justify-center flex flex-col   space-y-8">
              <Link to={"/"} className="min-w-max outline-none">
                <img
                  src={IMAGES.logo}
                  alt="Colored Logo"
                  className="lg:h-24 h-12 md:min-w-max"
                />
              </Link>
              <div className="flex flex-col sm:w-full xs:w-[90%]  items-center justify-center space-y-6 px-3">
                <h1 className="text-[1.75rem] font-semibold text-gray-1 font-poppins text-center min-w-max">
                  Forgot password?
                </h1>
                <p className="font-normal text-lg leading-[21px] !mt-3 text-[#828282]">
                  Reset your password
                </p>
                <Formik
                  initialValues={{ emailOrMobile: "" }}
                  validationSchema={validationSchema}
                  validateOnChange={true}
                  validateOnBlur={true}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className="flex flex-col sm:w-full xs:w-[90%] !mt-10 space-y-4"
                    >
                      <div className="flex flex-col sm:w-full space-y-4">
                        <div>
                          <Input
                            value={values.emailOrMobile}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="emailOrMobile"
                            label="Email or Mobile number"
                            type="text"
                            placeholder="Type email or Mobile number"
                          />
                          {errors.emailOrMobile && touched.emailOrMobile && (
                            <div className="text-red-500 text-sm">
                              {errors.emailOrMobile}
                            </div>
                          )}
                        </div>
                      </div>
                      <Button
                        className="!mt-[30px]"
                        disabled={isPending || isSubmitting}
                        type="submit"
                        title={
                          isPending ? (
                            <Spinner isVisible={isPending} />
                          ) : (
                            "Send OTP"
                          )
                        }
                      />
                    </form>
                  )}
                </Formik>
                <Link
                  to="/help-support"
                  className="text-custom-blue hover:text-blue-500 underline underline-offset-4"
                >
                  Need help?
                </Link>
              </div>
            </div>
          </div>
        ) : (
          // OTP Form
          <div className="bg-[#6f9cff] flex w-full h-screen items-center justify-center relative overflow-hidden">
            <img
              src={IMAGES.backgroundLogo}
              className="absolute bottom-0 left-0"
              alt=""
            />
            <img
              src={IMAGES.logoWithName}
              className="absolute top-0 right-0"
              alt=""
            />
            <Formik
              initialValues={{ otp: "" }}
              validationSchema={otpValidationSchema}
              onSubmit={handleOtpSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="w-full max-w-[816px] bg-white rounded-xl flex flex-col items-center justify-center z-20 py-9 mx-3"
                >
                  <div className="flex justify-center">
                    <Link to={"/"} className="min-w-max outline-none">
                      <img
                        src={IMAGES.logo}
                        alt="Colored Logo"
                        className="lg:h-24 h-12 md:min-w-max"
                      />
                    </Link>
                  </div>
                  <div className="flex items-center justify-center pt-5 md:pt-12">
                    <h1 className="text-[28px] font-semibold font-poppins text-gray-1">
                      Enter OTP
                    </h1>
                  </div>
                  <div className="flex items-center justify-center text-center pt-5 px-4">
                    <h1 className="md:text-lg font-normal font-poppins text-gray-4">
                      OTP has been sent to your registered email id.
                    </h1>
                  </div>
                  <div className="flex flex-col text-start pt-9 w-full max-w-[402px] px-4 md:px-0">
                    <label
                      htmlFor="otp"
                      className="text-base font-medium font-poppins text-gray-1"
                    >
                      Enter OTP
                    </label>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      className={`w-full max-w-[402px] mx-auto h-[44px] rounded-lg px-4 border outline-none text-base font-normal font-poppins ${
                        touched.otp && errors.otp
                          ? "border-red-500"
                          : "border-gray-300"
                      } mt-2`}
                      placeholder="0000"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.otp}
                    />
                    {touched.otp && errors.otp && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.otp}
                      </div>
                    )}
                  </div>
                  <div className="my-9 w-full px-4 mx-auto flex items-start">
                    <button
                      type="submit"
                      className="w-full max-w-[400px] mx-auto h-[48px] text-base font-medium font-poppins text-white bg-[#6f9cff] rounded-lg"
                    >
                      {isPending ? "Loading" : "Verify OTP"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};
