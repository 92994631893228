
const IMAGES = {
    backgroundLogo: require("./images/logo.png"),
    logoWithName: require("./images/logo-with-name.png"),
    coloredLogoWithName: require("./images/colored-logo-with-name.png"),
    googleLogo: require("./images/google-logo.png"),
    appleLogo: require("./images/apple-logo.png"),
    profilePic: require("../assets/images/profile-pic.png"),
    mainAd: require("../assets/images/main-advertisement.png"),
    sipAd: require("../assets/images/sipAd.png"),
    mustang: require("../assets/images/mustang.png"),
    winnerPic: require("../assets/images/winner-image.png"),
    mybids1: require("../assets/images/mybids-1.png"),
    reviewPic: require("../assets/images/reviewPic.png"),
    adPlaceholder: require("../assets/images/video-ad-placeholder.png"),
    logo: require("../assets/images/quickbider logo png.png")

}

export default IMAGES