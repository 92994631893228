import React from "react";
// import { RecentWinnersSection } from "./RecentWinnersSection";
import { AllParticipents } from "./AllParticipents";
import BidResultMustang from "./BidResultMustang";
import BidResultMyBidSection from "./BidResultMyBidSection";
import { DashboardLayout } from "components/Layout";
import { RecentWinnersSection } from "pages/DashboardPages/Dashboard/Components/RecentWinnersSection";
import Svgs from "assets/svgs";
import { useNavigate } from "react-router-dom";

function BidResult() {
  const navigate = useNavigate();

  return (
    <DashboardLayout activeSidebar={"Dashboard"}>
      <div className="">
        <div className="grid grid-cols-12 gap-5">
          <div className="lg:col-span-7 col-span-12">
            <div className="recentWinner flex flex-col w-full gap-8 lg:col-span-4">
              {/* <RecentWinnersSection /> */}

              <div className="flex flex-row gap-x-4 items-center min-w-max">
                <button onClick={() => navigate(-1)}>
                  <Svgs.BackArrow />
                </button>

                <div className="flex flex-row">
                  <button
                    onClick={() => navigate(-1)}
                    className=" text-gray-1 font-normal text-lg"
                  >
                    Dashboard/
                  </button>
                  <p className="text-custom-blue font-semibold text-lg">
                    Results
                  </p>
                </div>
              </div>
              <div className="flex flex-row w-full font-poppins font-semibold items-center justify-between gap-5">
                <h3 className=" lg:text-xl text-base text-gray-1 min-w-max">
                  Winners
                </h3>
                <div className="border-[#F5F5F5] border-2 w-full"></div>
              </div>
              <RecentWinnersSection hideHeading />
            </div>
            <div className="allparticipetns py-4">
              <AllParticipents />
            </div>
          </div>
          <div className="mustang lg:col-span-5 col-span-12">
            <div>
              <BidResultMustang />
            </div>
            <div className="mybidsection">
              <BidResultMyBidSection />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default BidResult;
