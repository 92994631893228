import { useEffect } from "react";
import { DropdownSidebar } from "../DropdownSidebar";
import { Navbar } from "../Navbar";
import { Sidebar } from "../Sidebar";
import { useLocation } from "react-router-dom";

export const DashboardLayout = ({ activeSidebar, children }) => {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='w-full flex'>
            <Sidebar activeSidebar={activeSidebar} />
            <div className='w-full flex flex-col'>
                <Navbar />
                <DropdownSidebar activeSidebar={activeSidebar} />
                <main className="w-full h-[calc(100vh-112px)] overflow-y-scroll xl:px-6 xl:pb-6 px-4 pb-4"> {/* Adjust padding as necessary */}
                    {children}
                </main>
            </div>
        </div>
    )
}
