export const getTime = (dateString) => {
  const date = new Date(dateString); // Create a new Date object from the input

  let hours = date.getUTCHours(); // Get the hours in UTC
  const minutes = date.getUTCMinutes(); // Get the minutes in UTC
  const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM/PM

  // Convert 24-hour clock to 12-hour clock
  hours = hours % 12 || 12; // If hours is 0 (midnight), set it to 12

  // Format minutes to always be 2 digits (e.g., 5 -> 05)
  const minutesFormatted = minutes.toString().padStart(2, "0");

  return `${hours}:${minutesFormatted} ${ampm}`; // Return the formatted time
};
