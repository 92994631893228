export const formatDateToDDMMYYYY = (date) => {
  const d = new Date(date); // Create a new Date object from the input

  // Get the day, month, and year
  const day = String(d.getDate()).padStart(2, "0"); // Get day and pad with leading 0 if necessary
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Get month (add 1 since getMonth() returns 0-indexed)
  const year = d.getFullYear(); // Get the full year

  return `${day}/${month}/${year}`; // Return the date in DD/MM/YYYY format
};

// Example usage:
const date = new Date();
const formattedDate = formatDateToDDMMYYYY(date);
