import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import reportWebVitals from "./reportWebVitals";
import { LoginContextProvider } from "./context/LoginContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactModal from "react-modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
ReactModal.setAppElement("#root");

const client_id =
  "462514691324-iqfo4rf1ut44sq06dtscvhdbsd7k60od.apps.googleusercontent.com";

root.render(
  <GoogleOAuthProvider clientId={client_id}>
    <LoginContextProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <App />
      </QueryClientProvider>
    </LoginContextProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
