import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Switch } from "@headlessui/react";
import IMAGES from "../../../assets/IMAGES";
import { OutlineButton } from "../../../components/OutlineButton";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { DashboardLayout } from "components/Layout";
import {
  getCity,
  getProvince,
  updateUserProfile,
  useGetUserProfile,
  useUpdateUserProfile,
} from "api/UserManagement";
import { toast } from "react-toastify";
import { useSettingsHelper } from "./helper";
import Spinner from "components/Spinner";
import { Formik } from "formik";
import Svgs from "assets/svgs";
import { UserProfileSkeleton } from "components/Skeleton/Components/Settings/UserProfileSkeleton";
import SelectDropdown from "components/SelectDropdown";

const ToggleSwitch = ({ enabled, setEnabled }) => (
  <Switch
    checked={enabled}
    onChange={setEnabled}
    className={`${
      enabled ? "bg-custom-blue" : "bg-gray-200"
    } relative inline-flex h-6 w-11 items-center rounded-full`}
  >
    <span
      className={`${
        enabled ? "translate-x-6" : "translate-x-1"
      } inline-block h-4 w-4 transform bg-white rounded-full transition-transform`}
    />
  </Switch>
);

const TABS = [
  "Account",
  "Security",
  "Bank details",
  "Notifications",
  "Privacy policy",
];

function Setting() {
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleSelectCity = (event) => {
    const value = event.target.value;
    setSelectedCity(value);
  };

  const handleSelectProvince = (event) => {
    const value = event.target.value;
    setSelectedProvince(value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const {
    fileInputRef,
    formikRef,
    // state
    activeTab,
    setActiveTab,
    previewProfileImage,
    setPreviewProfileImage,
    notificationPreferences,
    setNotificationPreferences,
    // api
    userData,
    isLoadingUserData,
    updatedUserData,
    isUpdating,
    // functions
    handleImageUpload,
    handleUpdateProfile,
    handleChangeUserData,
  } = useSettingsHelper();

  // const handleUpdateUserProfile = async (userData) => {
  //   try {
  //     const response = await updateUserProfile(userData);
  //     console.log("Profile updated successfully:", response);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  // Updated handleUpdateUserProfile to integrate POST API
  const handleUpdateUserProfile = async (userData) => {
    setLoading(true);
    try {
      const response = await updateUserProfile(userData);
      toast.success("Profile updated successfully");
      console.log("Profile updated successfully:", response);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  const fetchCityData = async () => {
    try {
      // setLoading(true);
      const data = await getCity();
      setCity(data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchProvinceData = async () => {
    try {
      // setLoading(true);
      const data = await getProvince();
      setProvince(data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchCityData();
    fetchProvinceData();
  }, []);

  return (
    <DashboardLayout activeSidebar={"Settings"}>
      <div className="flex flex-col gap-5">
        <h1 className=" text-2xl font-medium font-poppins text-gray-1">
          Settings
        </h1>
        <div className=" lg:max-w-[850px]  border rounded-[10px] p-5 flex flex-col gap-4 ">
          <div className="overflow-x-auto">
            <div className="flex flex-wrap  min-w-max flex-row gap-6 justify-center sm:justify-start space-x-0 sm:space-x-4 mb-4">
              {TABS.map((tab) => (
                <button
                  key={tab}
                  className={`pb-1 mx-1 sm:mx-0  text-base font-normal  ease-in-out duration-75 ${
                    activeTab === tab
                      ? "border-b-2 border-custom-blue text-custom-blue font-medium "
                      : "text-gray-4 hover:border-b-2 hover:border-custom-blue hover:text-custom-blue"
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>

          {activeTab === "Account" &&
            (isLoadingUserData ? (
              <UserProfileSkeleton />
            ) : (
              <>
                <div className="w-full flex flex-col md:flex-row gap-6 justify-between items-center">
                  {/* image upload functionlaity  */}

                  <div className="flex flex-col md:flex-row items-center gap-4 md:gap-5">
                    {/* Profile Image */}
                    <div className="w-[120px] h-[120px]">
                      {preview ? (
                        <img
                          // className="w-full h-full object-cover rounded-full"
                          src={preview}
                          alt="Profile"
                          className="w-[120px] h-[120px] rounded-xl mr-4 mb-4 sm:mb-0 border object-cover"
                        />
                      ) : (
                        <div className="w-[120px] h-[120px] flex items-center justify-center bg-custom-blue rounded-[18px] text-2xl font-bold text-white">
                          {updatedUserData?.first_name?.[0]?.toUpperCase() +
                            updatedUserData?.last_name?.[0]?.toUpperCase()}
                        </div>
                      )}
                    </div>

                    {/* User Info */}
                    <div className="flex flex-col gap-2 md:gap-3">
                      <h3 className="text-center md:text-start text-lg font-semibold text-[#333333]">
                        {updatedUserData?.first_name}{" "}
                        {updatedUserData?.last_name}
                      </h3>
                      <p className="text-gray-500">{updatedUserData?.email}</p>
                    </div>
                  </div>

                  {/* Update Profile Button */}
                  <div>
                    <input
                      id="profileImage"
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor="profileImage"
                      className="w-48 h-12 px-4 text-sm font-normal font-poppins cursor-pointer border border-blue-500 text-blue-500 rounded-lg hover:bg-blue-500 hover:text-white transition flex items-center justify-center"
                    >
                      Update Profile Picture
                    </label>

                    {/* <Button
                      customTheme={"btn-outline"}
                      // onClick={() => {
                      //   if (fileInputRef.current) {
                      //     fileInputRef.current.click(); // Trigger click on the hidden file input
                      //   }
                      // }}
                      className="px-4 text-sm font-normal font-poppins"
                      title="Update Profile Picture"
                    /> */}
                  </div>
                </div>
                {/* details form   */}
                <div className="pt-3">
                  <div>
                    <h1 className="font-medium text-base font-poppins">
                      Personal details
                    </h1>
                  </div>

                  <Formik
                    innerRef={formikRef}
                    enableReinitialize
                    initialValues={{
                      first_name: updatedUserData?.first_name || "",
                      last_name: updatedUserData?.last_name || "",
                      phone_number: updatedUserData?.phone_number || "",
                      email: updatedUserData?.email || "",
                      street_address: updatedUserData?.street_address || "",
                      zip_code: updatedUserData?.zip_code || "",
                      city: updatedUserData?.city || 1,
                      province: updatedUserData?.province || 1,
                    }}
                    onSubmit={(values) => {
                      const formData = new FormData();

                      // Append form input fields to FormData
                      formData.append("first_name", values.first_name);
                      formData.append("last_name", values.last_name);
                      formData.append("phone_number", values.phone_number);
                      formData.append("email", values.email);
                      formData.append("address", values.street_address);
                      formData.append("zipcode", values.zip_code);
                      formData.append("city", selectedCity);
                      formData.append("province", selectedProvince);

                      // Append image file to FormData
                      if (image) {
                        formData.append("display_pic", image);
                      }

                      // Pass the FormData object to the API
                      handleUpdateUserProfile(formData);
                    }}
                  >
                    {({
                      values,
                      handleBlur,
                      handleChange,
                      errors,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit} className="space-y-8">
                        <div className="space-y-5">
                          {/* First Name and Last Name */}
                          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 pt-3">
                            <div className="w-full">
                              <Input
                                label="Enter first name"
                                placeholder="First name"
                                value={values.first_name}
                                name="first_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="w-full">
                              <Input
                                label="Enter last name"
                                placeholder="Last name"
                                value={values.last_name}
                                name="last_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>

                          {/* Phone Number and Email */}
                          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                            <div className="w-full">
                              <Input
                                type="number"
                                label="Phone Number"
                                placeholder="Enter phone number"
                                value={values.phone_number}
                                name="phone_number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="w-full">
                              <Input
                                disabled
                                label="Email ID"
                                placeholder="Email id"
                                value={values.email}
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>

                          {/* Street Address and Zip Code */}
                          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                            <div className="w-full">
                              <Input
                                label="Street address"
                                placeholder="Enter street address"
                                value={values.street_address}
                                name="street_address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="w-full">
                              <Input
                                label="Zip code"
                                placeholder="Type nearby location"
                                value={values.zip_code}
                                name="zip_code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>

                          {/* City and Province */}
                          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                            <SelectDropdown
                              id="city"
                              htmlFor="city"
                              label="City"
                              optionPlaceholder="Select City"
                              options={city?.results}
                              value={selectedCity}
                              handleSelectChange={handleSelectCity}
                            />{" "}
                            <SelectDropdown
                              id="province"
                              htmlFor="province"
                              label="Province"
                              optionPlaceholder="Select Province"
                              options={province?.results}
                              value={selectedProvince}
                              handleSelectChange={handleSelectProvince}
                            />
                          </div>
                        </div>

                        {/* Submit Button */}
                        <div className="w-full text-center flex items-center justify-center">
                          <Button
                            disabled={loading}
                            type="submit"
                            className="!w-[190px] text-sm font-inter self-center items-center"
                            title={
                              loading ? <Spinner height={20} /> : "Save changes"
                            }
                          />
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </>
            ))}

          {activeTab === "Security" && (
            <>
              <div className="space-y-2">
                <div>
                  <h1 className="font-medium text-gray-1 text-base">
                    Update pasword
                  </h1>
                </div>
                <form onSubmit={handleSubmit} className="space-y-8">
                  <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 pt-3">
                    <div className="w-full">
                      <Input
                        label={"New password"}
                        placeholder={"Type new password"}
                      />
                    </div>
                    <div className="w-full">
                      <Input
                        label={"Confirm new password"}
                        placeholder={"Confirm new password"}
                      />
                    </div>
                  </div>
                  <div className="text-center w-full">
                    <Button
                      className={"!w-[190px]"}
                      title={"Update password"}
                    />
                  </div>
                </form>
              </div>
            </>
          )}
          {activeTab === "Bank details" && (
            <>
              <div className="">
                <div>
                  <h1 className="font-medium text-gray-1 text-base">
                    Bank details
                  </h1>
                </div>
                <form onSubmit={handleSubmit} className="space-y-8">
                  <div className="space-y-4">
                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 pt-3">
                      <div className="w-full">
                        <Input
                          label={"Account number"}
                          placeholder={"Enter account number"}
                        />
                      </div>
                      <div className="w-full">
                        <Input
                          label={"Confirm account number"}
                          placeholder={"Confirm account number"}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                      <div className="w-full">
                        <Input
                          label={"IFSC code"}
                          placeholder={"ICIC00033343"}
                        />
                      </div>
                      <div className="w-full">
                        <Input
                          label={"Bank name"}
                          placeholder={"Enter bank name"}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                      <div className="md:w-2/4 w-full">
                        <Input
                          label={"Name on account"}
                          placeholder={"Robert Fox"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-center w-full">
                    <Button
                      className={"!w-[190px]"}
                      title={"Update bank details"}
                    />
                  </div>
                </form>
              </div>
            </>
          )}
          {activeTab === "Notifications" && (
            <>
              <div className=" w-[100%]">
                <div>
                  <h1 className="font-medium">Notifications</h1>
                </div>

                <div className="pt-6">
                  <div className="flex items-center justify-between mb-4 border-b-[1px] pb-3 px-2">
                    <label className="text-gray-1 font-medium">
                      Notifications
                    </label>
                    <ToggleSwitch
                      enabled={notificationPreferences.notificationsEnabled}
                      setEnabled={() => {
                        setNotificationPreferences({
                          ...notificationPreferences,
                          notificationsEnabled:
                            !notificationPreferences.notificationsEnabled,
                        });
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-between mb-4 border-b-[1px] pb-3 px-2">
                    <label className="text-gray-1 font-medium">
                      Notifications Sound
                    </label>
                    <ToggleSwitch
                      enabled={notificationPreferences?.soundEnabled}
                      setEnabled={() => {
                        setNotificationPreferences({
                          ...notificationPreferences,
                          soundEnabled: !notificationPreferences?.soundEnabled,
                        });
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-between mb-4 border-b-[1px] pb-3 px-2">
                    <label className="text-gray-1 font-medium">
                      Send to email id
                    </label>
                    <ToggleSwitch
                      enabled={notificationPreferences?.sendToEmail}
                      setEnabled={() => {
                        setNotificationPreferences({
                          ...notificationPreferences,
                          sendToEmail: !notificationPreferences.sendToEmail,
                        });
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-between border-b-[1px] pb-3 px-2">
                    <label className="text-gray-1 font-medium">
                      Send to phone
                    </label>
                    <ToggleSwitch
                      enabled={notificationPreferences?.sendToPhone}
                      setEnabled={() => {
                        setNotificationPreferences({
                          ...notificationPreferences,
                          sendToPhone: !notificationPreferences.sendToPhone,
                        });
                      }}
                    />
                  </div>
                  <div className="w-full items-center flex justify-center pt-5">
                    <Button
                      className={"!w-[190px]"}
                      title={"Update Settings"}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {activeTab === "Privacy policy" && (
            <div className="max-w-[755px]">
              <h3 className="text-[20px] font-semibold leading-[30px] text-black">
                1. Information We Collect
              </h3>
              <p className="text-base font-medium leading-6 text-[#828282] mt-5">
                When you visit or interact with [Your Website Name], we may
                collect the following information:
              </p>
              <li className="text-base font-medium leading-6 text-[#828282]">
                Personal Information: Name, email address, phone number, mailing
                address, and any other details you provide during registration
                or contact.
              </li>
              <li className="text-base font-medium leading-6 text-[#828282]">
                Vehicle Information: Details of the car you are selling or
                purchasing, including make, model, year, VIN number, photos, and
                any other relevant information.
              </li>
              <li className="text-base font-medium leading-6 text-[#828282]">
                Payment Information: Credit card or banking details when you
                process transactions through our platform.
              </li>
              <li className="text-base font-medium leading-6 text-[#828282]">
                Usage Data: Information about how you access and use the site,
                including IP addresses, browser type, operating system, and
                referring website.
              </li>
              <h3 className="text-[20px] font-semibold leading-[30px] text-black">
                2. How We Use Your Information
              </h3>
              <p className="text-base font-medium leading-6 text-[#828282] mt-5">
                We use the collected information to:
              </p>
              <li className="text-base font-medium leading-6 text-[#828282]">
                Facilitate Transactions: To allow you to list and sell your car,
                or to connect you with buyers or dealers.
              </li>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Setting;
