import React, { useState } from "react";
import IMAGES from "assets/IMAGES";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import zxcvbn from "zxcvbn";
import Spinner from "components/Spinner";
import { setNewPassword } from "api/UserManagement";
import { toast } from "react-toastify";

// Validation Schema
const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export const ResetPassword = () => {
  const [isPending, setIsPending] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(null);
  const navigate = useNavigate();

  const emailOrMobile = localStorage.getItem("emailOrMobile");

  // Form submission handler
  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      new_password: values.newPassword,
      email_or_phone_no: emailOrMobile,
    };
    setIsPending(true);

    try {
      const response = await setNewPassword(data);
      setIsPending(false);
      setSubmitting(false);
      toast.success("Password reset successful.");
      navigate("/login");
    } catch (error) {
      console.error("API error:", error);
      // Handle error (e.g., show error message)
      setIsPending(false);
      setSubmitting(false);
      toast.success("Failed to reset password. Please try again.");
    }
  };

  // Function to evaluate password strength
  const evaluatePasswordStrength = (password) => {
    const result = zxcvbn(password);
    setPasswordStrength(result);
  };

  return (
    <div className="bg-custom-blue relative z-50 h-screen flex justify-center items-center px-[10px] overflow-hidden">
      <img
        src={IMAGES.backgroundLogo}
        alt=""
        className="absolute right-0 top-0 h-[36rem] -rotate-8"
      />
      <img
        src={IMAGES.logoWithName}
        alt=""
        className="absolute left-0 bottom-10 h-60 -rotate-8 -z-50"
      />
      <div className="bg-white py-9 rounded-xl flex flex-col w-full max-w-[876px] items-center justify-center z-50 overflow-y-scroll">
        <div className="lg:w-3/4 w-full items-center justify-center flex flex-col">
          <Link to={"/"} className="min-w-max outline-none">
            <img
              src={IMAGES.logo}
              alt="Colored Logo"
              className="max-w-[200px] md:max-w-[374px]"
            />
          </Link>
          <div className="flex flex-col sm:w-full xs:w-[90%] items-center justify-center px-3 pt-12">
            <h1 className="text-lg md:text-[1.75rem] font-semibold text-gray-1 font-poppins text-center min-w-max">
              Create new password
            </h1>
            <div>
              <p className="font-normal text-xs md:text-base font-inter pt-4 text-[#828282]">
                Please enter a new password that meets the following criteria:
              </p>
              <ul className="list-disc ml-6">
                <li className="text-xs md:text-base font-normal font-inter text-gray-4">
                  At least 8 characters long
                </li>
                <li className="text-xs md:text-base font-normal font-inter text-gray-4">
                  Includes both upper and lowercase letters
                </li>
                <li className="text-xs md:text-base font-normal font-inter text-gray-4">
                  Contains at least one number and one special character
                </li>
              </ul>
            </div>
            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form className="flex flex-col w-full max-w-[402px] !mt-10 space-y-4">
                  <div className="flex flex-col space-y-4">
                    <div>
                      <Field
                        name="newPassword"
                        type="password"
                        as={Input}
                        label="New password"
                        placeholder="Type new password"
                        onChange={(e) => {
                          handleChange(e);
                          evaluatePasswordStrength(e.target.value);
                        }}
                      />
                      <ErrorMessage
                        name="newPassword"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                      {passwordStrength && (
                        <div className="mt-2">
                          <div
                            className={`w-full h-2 rounded bg-gray-200 mt-1`}
                          >
                            <div
                              className={`h-2 rounded ${
                                passwordStrength.score >= 3
                                  ? "bg-green-500"
                                  : passwordStrength.score === 2
                                  ? "bg-yellow-500"
                                  : "bg-red-500"
                              }`}
                              style={{
                                width: `${(passwordStrength.score + 1) * 20}%`,
                              }}
                            ></div>
                          </div>
                          <p className="text-xs mt-1">
                            {passwordStrength.feedback.suggestions.length > 0
                              ? passwordStrength.feedback.suggestions.join(", ")
                              : passwordStrength.score >= 3
                              ? "Strong password"
                              : "Weak password"}
                          </p>
                        </div>
                      )}
                    </div>
                    <div>
                      <Field
                        name="confirmPassword"
                        type="password"
                        as={Input}
                        label="Confirm password"
                        placeholder="Confirm new password"
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>
                  <Button
                    className="!mt-[30px]"
                    type="submit"
                    title={isPending ? <Spinner /> : "Save"}
                    disabled={isSubmitting || isPending}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
